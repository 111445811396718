import React, { useContext } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Press from "../components/page-components/press";
import { WebsiteContext } from "../context/WebsiteContext";

const PressPageContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};
`;

const ContactPage = ({ data }) => {
  const { distanceFromTop } = useContext(WebsiteContext);
  return (
    <Layout>
      <SEO title="Press" />
      <PressPageContainer distanceFromTop={distanceFromTop}>
        <Press data={data} />
      </PressPageContainer>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query {
    posts: allWpPost {
      edges {
        node {
          id
          title
          content
          date(formatString: "MM/DD/YYYY")
          post_acf {
            articleUrl
          }
        }
      }
    }
  }
`;
