import React from "react";
import styled from "styled-components";

import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";

const PressContainer = styled.div`
  .press-wrapper {
    ${media.large`max-width: 1000px;`}
  }
  .page-title {
    padding: 70px 0 70px;
    text-align: center;

    h1 {
      margin: 0;
      text-transform: uppercase;
      font-weight: 900;
      color: #222;
    }
  }

  .item-container {
    margin-bottom: 60px;
    .inner-wrap {
      .content-container {
        h3 {
          text-transform: uppercase;
          font-weight: 900;
          font-size: 21px;
          margin: 0 0 10px;
          color: #222;
          line-height: 1.2;

          ${media.small`font-size: 32px;`}
        }

        > p {
          font-style: italic;
        }

        .press-content {
          font-weight: 300;
          font-size: 14px;
          ${media.small`font-size: 16px;`}
        }

        .link-container {
          a {
            color: #000;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 19px;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
`;

const Press = ({ data }) => {
  let posts = data?.posts?.edges;
  return (
    <PressContainer>
      <Wrapper className="press-wrapper">
        <div className="page-title">
          <h1>Press</h1>
        </div>
        {posts.map((edge, edgeIndex) => {
          let post = edge.node;
          const { title, content, post_acf, date } = post || {};
          return (
            <div className="item-container" key={edgeIndex}>
              <div className="inner-wrap">
                <div className="content-container">
                  <h3>{title}</h3>
                  <p>{date}</p>
                  <div
                    className="press-content"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                  <div className="link-container">
                    <a target="_blank" href={post_acf.articleUrl}>
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Wrapper>
    </PressContainer>
  );
};

export default Press;
